import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { PurchaseType } from '@/types/entities'

interface PurchaseTypesState {
  value: PurchaseType[] | null
}

const initialState = (): PurchaseTypesState => {
  return {
    value: null,
  }
}

const purchaseTypesSlice = createSlice({
  name: 'purchaseTypes',
  initialState,
  reducers: {
    setPurchaseTypes(state, action: PayloadAction<PurchaseType[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getPurchaseTypes: (sliceState) => sliceState.value,
  },
})

export const { setPurchaseTypes } = purchaseTypesSlice.actions
export const { getPurchaseTypes } = purchaseTypesSlice.selectors
export default purchaseTypesSlice.reducer
