'use client'

import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export default ((): React.ReactElement => {
  return (
    <div className="text-baseSM">
      <p className="my-4">
        Our models are <b>not</b> trained for:
      </p>
      <div className="mb-12 rounded bg-gray-50 px-6 py-4">
        <ul className="block md:flex">
          <li className="mb-2 mr-0 flex items-center md:mb-0 md:mr-8">
            <FontAwesomeIcon icon={faCircleXmark} className="mr-2 size-4 text-red-700" />
            <span>Logo</span>
          </li>
          <li className="mb-2 mr-0 flex items-center md:mb-0 md:mr-8">
            <FontAwesomeIcon icon={faCircleXmark} className="mr-2 size-4 text-red-700" />
            <span>Typography</span>
          </li>
          <li className="mb-2 mr-0 flex items-center md:mb-0 md:mr-8">
            <FontAwesomeIcon icon={faCircleXmark} className="mr-2 size-4 text-red-700" />
            <span>Icons</span>
          </li>
          <li className="mr-0 flex items-center md:mr-8">
            <FontAwesomeIcon icon={faCircleXmark} className="mr-2 size-4 text-red-700" /> <span>Colors</span>
          </li>
        </ul>
      </div>

      <dl className="mb-10">
        <dt className="mb-6">
          Step-by-step guide on creating a prompt for AI image generation, emphasizing the scene surrounding one or two main subjects:
        </dt>
        <dd className="mb-6">
          <b>Identify the Main Subjects:</b> Decide on one or two main subjects you want to feature prominently in the generated image. For
          example, it could be a person, an animal, an object, or even a specific landscape feature.
        </dd>
        <dd className="mb-6">
          <b>Outline the Scene:</b> Describe the environment or setting where the subjects are situated. Focus on details such as the
          weather, time of day, geographical features, and any relevant objects or structures in the surroundings.
        </dd>
        <dd className="mb-6">
          <b>Provide Context:</b> Explain the relationship between the main subjects and the environment. Are they interacting with their
          surroundings? Is there a specific mood or atmosphere you want to convey through the scene?
        </dd>
        <dd className="mb-6">
          <b>Use Descriptive Language:</b> Use vivid and descriptive language to paint a clear picture of the scene in your prompt. Include
          sensory details like textures, sounds, and smells to enrich the image.
        </dd>
        <dd className="mb-6">
          <b>Specify Details:</b> While the main subjects are important, allocate most of your description to the scene around them.
          Describe the background, foreground, and any elements that contribute to the overall composition of the image.
        </dd>
        <dd className="mb-6">
          <b>Consider Composition:</b> Think about how you want the image to be composed. Are there specific perspectives, angles, or
          framing considerations that would enhance the visual impact of the scene?
        </dd>
        <dd>
          <b>Keep it Concise and Clear:</b> Ensure that your prompt is concise yet comprehensive. Avoid overly complex or ambiguous language
          that could lead to misinterpretations by the AI model.
        </dd>
      </dl>

      <div>
        <p className="mb-2">Example Prompt:</p>
        <div className="mb-8 border-l-2 border-gray-200 bg-gray-50 p-6 italic">
          In a serene mountain lake at dawn, a lone fisherman is casting a line into the water as rays of sunlight peek over the distant
          peaks with tall pine trees along the shore.
        </div>
        <p>
          By following these steps, you can effectively guide the AI in generating an image that captures the essence of your envisioned
          scene while focusing on the surrounding details that enhance its richness and depth.
        </p>
      </div>
    </div>
  )
}) satisfies React.FC<Props>
