import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface UserMenuState {
  isUserMenuOpen: boolean
  isMobileUserMenuOpen: boolean
}

const initialState = (): UserMenuState => {
  return {
    isUserMenuOpen: false,
    isMobileUserMenuOpen: false,
  }
}

const userMenuSlice = createSlice({
  name: 'userMenu',
  initialState,
  reducers: {
    setIsUserMenuOpen(state, action: PayloadAction<boolean>) {
      state.isUserMenuOpen = action.payload
    },
    setIsMobileUserMenuOpen(state, action: PayloadAction<boolean>) {
      state.isMobileUserMenuOpen = action.payload
    },
  },
  selectors: {
    getIsUserMenuOpen: (sliceState) => sliceState.isUserMenuOpen,
    getIsMobileUserMenuOpen: (sliceState) => sliceState.isMobileUserMenuOpen,
  },
})

export const { setIsUserMenuOpen, setIsMobileUserMenuOpen } = userMenuSlice.actions
export const { getIsUserMenuOpen, getIsMobileUserMenuOpen } = userMenuSlice.selectors
export default userMenuSlice.reducer
