export const PASSWORD_HASH_SALT = 'ac90d1b18189'

// Google's limit is 50,000 URLs per sitemap
export const URLS_PER_SITEMAP = 5000

// TODO: Allow more later when we can follow for more tokens: https://github.com/huggingface/diffusers/issues/2136
export const MAX_USER_PROMPT_TOKENS = 35
export const MAX_USER_NEGATIVE_PROMPT_TOKENS = 10

export const DataUpdated = {
  USER: 'USER',
  USER_MEMBERSHIP: 'USER_MEMBERSHIP',
  USER_CREDITS: 'USER_CREDITS',
} as const

export type DataUpdatedTypes = keyof typeof DataUpdated
