import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { MembershipType } from '@/types/entities'

interface MembershipTypesState {
  value: MembershipType[] | null
}

const initialState = (): MembershipTypesState => {
  return {
    value: null,
  }
}

const membershipTypesSlice = createSlice({
  name: 'membershipTypes',
  initialState,
  reducers: {
    setMembershipTypes(state, action: PayloadAction<MembershipType[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getMembershipTypes: (sliceState) => sliceState.value,
  },
})

export const { setMembershipTypes } = membershipTypesSlice.actions
export const { getMembershipTypes } = membershipTypesSlice.selectors
export default membershipTypesSlice.reducer
