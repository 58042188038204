import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { MembershipPeriod } from '@/types/entities'

interface MembershipPeriodsState {
  value: MembershipPeriod[] | null
}

const initialState = (): MembershipPeriodsState => {
  return {
    value: null,
  }
}

const membershipPeriodsSlice = createSlice({
  name: 'membershipPeriods',
  initialState,
  reducers: {
    setMembershipPeriods(state, action: PayloadAction<MembershipPeriod[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getMembershipPeriods: (sliceState) => sliceState.value,
  },
})

export const { setMembershipPeriods } = membershipPeriodsSlice.actions
export const { getMembershipPeriods } = membershipPeriodsSlice.selectors
export default membershipPeriodsSlice.reducer
