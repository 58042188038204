import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Gender } from '@/types/entities'

interface GendersState {
  value: Gender[] | null
}

const initialState = (): GendersState => {
  return {
    value: null,
  }
}

const gendersSlice = createSlice({
  name: 'genders',
  initialState,
  reducers: {
    setGenders(state, action: PayloadAction<Gender[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getGenders: (sliceState) => sliceState.value,
  },
})

export const { setGenders } = gendersSlice.actions
export const { getGenders } = gendersSlice.selectors
export default gendersSlice.reducer
