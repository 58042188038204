import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Style } from '@/types/entities'
import type { EnumStyle } from '../../../../../../../lib/database/lib/generated/enums'

interface StylesState {
  value: Style[] | null
  selected: keyof typeof EnumStyle | null
}

const initialState = (): StylesState => {
  return {
    value: null,
    selected: null,
  }
}

const stylesSlice = createSlice({
  name: 'styles',
  initialState,
  reducers: {
    setStyles(state, action: PayloadAction<Style[] | null>) {
      state.value = action.payload
    },
    setSelectedStyle(state, action: PayloadAction<keyof typeof EnumStyle | null>) {
      state.selected = action.payload
    },
  },
  selectors: {
    getStyles: (sliceState) => sliceState.value,
    getSelectedStyle: (sliceState) => sliceState.selected,
  },
})

export const { setStyles, setSelectedStyle } = stylesSlice.actions
export const { getStyles, getSelectedStyle } = stylesSlice.selectors
export default stylesSlice.reducer
