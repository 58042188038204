import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Price } from '@/types/entities'

interface PricesState {
  value: Price[] | null
}

const initialState = (): PricesState => {
  return {
    value: null,
  }
}

const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setPrices(state, action: PayloadAction<Price[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getPrices: (sliceState) => sliceState.value,
  },
})

export const { setPrices } = pricesSlice.actions
export const { getPrices } = pricesSlice.selectors
export default pricesSlice.reducer
