import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AuthProvider } from '@/types/entities'

interface AuthProvidersState {
  value: AuthProvider[] | null
}

const initialState = (): AuthProvidersState => {
  return {
    value: null,
  }
}

const authProvidersSlice = createSlice({
  name: 'authProviders',
  initialState,
  reducers: {
    setAuthProviders(state, action: PayloadAction<AuthProvider[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getAuthProviders: (sliceState) => sliceState.value,
  },
})

export const { setAuthProviders } = authProvidersSlice.actions
export const { getAuthProviders } = authProvidersSlice.selectors
export default authProvidersSlice.reducer
