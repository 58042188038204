import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, useStore } from 'react-redux'
import currentUserReducer from '@/lib/features/currentUser/currentUserSlice'
import authProvidersReducer from '@/lib/features/authProviders/authProvidersSlice'
import couponsReducer from './features/coupons/couponsSlice'
import gendersReducer from './features/genders/gendersSlice'
import stylesReducer from './features/styles/stylesSlice'
import membershipTypesReducer from './features/membershipTypes/membershipTypesSlice'
import membershipPeriodsReducer from './features/membershipPeriods/membershipPeriodsSlice'
import purchaseTypesReducer from './features/purchaseTypes/purchaseTypesSlice'
import verifyTypesReducer from './features/verifyTypes/verifyTypesSlice'
import pricesReducer from './features/prices/pricesSlice'
import versionReducer from './features/version/versionSlice'
import tokenReducer from './features/token/tokenSlice'
import userMenuReducer from '@/lib/features/userMenu/userMenuSlice'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const makeStore = () => {
  return configureStore({
    reducer: {
      currentUser: currentUserReducer,
      coupons: couponsReducer,
      genders: gendersReducer,
      styles: stylesReducer,
      membershipTypes: membershipTypesReducer,
      membershipPeriods: membershipPeriodsReducer,
      authProviders: authProvidersReducer,
      purchaseTypes: purchaseTypesReducer,
      verifyTypes: verifyTypesReducer,
      prices: pricesReducer,
      version: versionReducer,
      token: tokenReducer,
      userMenu: userMenuReducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
  })
}

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()
export const useAppStore = useStore.withTypes<AppStore>()
