import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface TokenState {
  value: string | null
}

const initialState = (): TokenState => {
  return {
    value: null,
  }
}

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getToken: (sliceState) => sliceState.value,
  },
})

export const { setToken } = tokenSlice.actions
export const { getToken } = tokenSlice.selectors
export default tokenSlice.reducer
