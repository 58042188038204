import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import type { DataUpdatedTypes } from '../../../../../../../lib/constants/lib'
import type { User } from '@/types/entities'

moment.tz.setDefault('America/New_York')

interface CurrentUserState {
  value: User | null
  lastDataUpdated: DataUpdatedTypes | null
  lastDataUpdatedAt: string | null
}

const initialState = (): CurrentUserState => {
  return {
    value: null,
    lastDataUpdated: null,
    lastDataUpdatedAt: null,
  }
}

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<User | null>) {
      state.value = action.payload
    },
    setLastDataUpdated(state, action: PayloadAction<DataUpdatedTypes | null>) {
      state.lastDataUpdated = action.payload
      state.lastDataUpdatedAt = moment().toISOString()
    },
  },
  selectors: {
    getCurrentUser: (sliceState) => sliceState.value,
    getCurrentUserIsLoggedIn: (sliceState) => !!sliceState.value,
    getLastDataUpdated: (sliceState) => sliceState.lastDataUpdated,
    getLastDataUpdatedAt: (sliceState) => moment(sliceState.lastDataUpdatedAt).toDate(),
  },
})

export const { setCurrentUser, setLastDataUpdated } = currentUserSlice.actions
export const { getCurrentUser, getCurrentUserIsLoggedIn, getLastDataUpdated, getLastDataUpdatedAt } = currentUserSlice.selectors
export default currentUserSlice.reducer
