import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface VersionState {
  value: string | null
}

const initialState = (): VersionState => {
  return {
    value: null,
  }
}

const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    setVersion(state, action: PayloadAction<string | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getVersion: (sliceState) => sliceState.value,
  },
})

export const { setVersion } = versionSlice.actions
export const { getVersion } = versionSlice.selectors
export default versionSlice.reducer
