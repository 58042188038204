import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { VerifyType } from '@/types/entities'

interface VerifyTypesState {
  value: VerifyType[] | null
}

const initialState = (): VerifyTypesState => {
  return {
    value: null,
  }
}

const verifyTypesSlice = createSlice({
  name: 'verifyTypes',
  initialState,
  reducers: {
    setVerifyTypes(state, action: PayloadAction<VerifyType[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getVerifyTypes: (sliceState) => sliceState.value,
  },
})

export const { setVerifyTypes } = verifyTypesSlice.actions
export const { getVerifyTypes } = verifyTypesSlice.selectors
export default verifyTypesSlice.reducer
